<template><div></div></template>
<script setup lang="ts">
  import type {
    User,
    ZonaDespacho,
    TipoPedido,
    FormaPago,
    Sello,
    ConfigAstro,
    Banner,
    Direccion,
    Categoria,
    Tienda,
    NivelSuscribibleData,
    Config,
  } from "@lib/interfaces";
  import { setearUsuario, cerrarSesion } from "@stores/user";
  import {
    setearZonasDespacho,
    setearTiposPedido,
    setearDireccionEncontrada,
    direccionActiva,
    setearZonasDespachoDisponibles,
    setearTiendas,
    seleccionarPrimeraVentanaDisponible,
    abrirModalDespachoLogin,
    setearComponenteActivoModalDespachoLogin,
    setearMembresiaModalDespacho,
    setearForzarCd,
    ventanaActiva,
    seleccionarProximaVentanaFutura,
    esDireccionDeTienda,
    setearDireccionTiendaParaRetiro,
  } from "@stores/despacho";
  import { setearFormasPago } from "@stores/checkout";
  import { getCarritoSegundoPlano } from "@stores/carro";
  import {
    setearConfigAstro,
    setearSellos,
    setearBanners,
    setearCategorias,
    setearConfigOpcionesActivacionMarca,
    setearConfigRadiosActivacionMarca,
    setearConfigAnilloReparto,
    setearMapsCargado,
    setearConfigNivelSuscribible,
    setearConfigMotivosCancelacionSuscripcion,
    setearConfigTerminosCondicionesMembresia,
    setearConfigTerminosCondiciones,
    setearBeneficioDespachoFrestarId,
    setearCuponBienvenidaId,
    setearReferidor,
    setearModalReferidos,
  } from "@stores/app";
  import { onMounted } from "@vue/runtime-core";
  import {
    getCookie,
    deleteCookie,
    borrarCookiesFuncionamiento,
  } from "@lib/cookies";
  import { loadUser } from "@lib/eventos";
  import { cookiesBorradas } from "@api/client/users";
  import { loadMap } from "@lib/plugins/googleMaps";
  import { useStore } from "@nanostores/vue";
  import { eliminarItemsGratis } from "@stores/carro";
  import { getReferidor } from "@api/client/users";

  interface Props {
    user: User | null;
    zonasDespacho: ZonaDespacho[];
    tiposPedido: TipoPedido[];
    formasPago: FormaPago[];
    sellos: Sello[];
    configAstroData: ConfigAstro;
    configOpcionesActivacionMarca: Array<string>;
    configRadiosActivacionMarca: Array<string>;
    configAnilloRepartoCheck: Array<number[]>;
    banners: Banner[];
    direccionId: string | undefined;
    categorias: Categoria[];
    tiendas: Tienda[];
    configNivelSuscribible: Config<NivelSuscribibleData> | undefined;
    configTerminosCondiciones: Config<string> | undefined;
    membresia: string | undefined;
    configMotivosCancelacionSuscripcion: Config<string[]> | undefined;
    configTerminosCondicionesMembresia: Config<string> | undefined;
    cuponBienvenidaId: number | undefined;
    beneficioDespachoFrestarId: number | undefined;
    codigoReferido: string | undefined;
    registroReferido: string | undefined;
    forzarDespachoFuturo: boolean;
  }
  const props = defineProps<Props>();
  const $direccionActiva = useStore(direccionActiva);
  const $ventanaActiva = useStore(ventanaActiva);

  //Seteo de Direccion desde Google
  let direccionUser: Direccion | undefined = undefined;

  //Si la URL de retorno trae una direccion_id (caso de direccion desde el localStorage), se setea
  if (props.direccionId && props.user) {
    direccionUser = props.user.direcciones.find((direccion) => {
      return props.direccionId == direccion.id;
    });
  }

  onMounted(async () => {
    //Limpieza Cookies y en caso de Token Inválido
    if (getCookie("token", document.cookie) && !props.user) {
      deleteCookie("token", document.cookie);
      cerrarSesion();
    }
    if (props.user && props.user.borrar_cookies) {
      borrarCookiesFuncionamiento();
      cookiesBorradas(props.user.id);
      cerrarSesion();
      return window.location.reload();
    }

    eliminarItemsGratis();
    const cookies = document.cookie;
    const zonaDespacho = getCookie("zonaDespacho", cookies);
    let tipoPedido = getCookie("tipoPedido", cookies);
    const ventana = getCookie("ventana", cookies);
    const bodega = getCookie("bodega", cookies);
    if (!tipoPedido) tipoPedido = "1";
    setearUsuario(props.user);
    setearZonasDespacho(props.zonasDespacho, zonaDespacho, ventana, bodega);
    setearTiposPedido(props.tiposPedido, tipoPedido);
    setearFormasPago(props.formasPago);
    setearSellos(props.sellos);
    setearConfigAstro(props.configAstroData);
    setearConfigMotivosCancelacionSuscripcion(
      props.configMotivosCancelacionSuscripcion?.data ?? [],
    );
    setearConfigOpcionesActivacionMarca(props.configOpcionesActivacionMarca);
    setearConfigRadiosActivacionMarca(props.configRadiosActivacionMarca);
    setearConfigAnilloReparto(props.configAnilloRepartoCheck);
    setearBeneficioDespachoFrestarId(props.beneficioDespachoFrestarId);
    setearCuponBienvenidaId(props.cuponBienvenidaId);
    if (props.configTerminosCondicionesMembresia) {
      setearConfigTerminosCondicionesMembresia(
        props.configTerminosCondicionesMembresia.data ?? "",
      );
    }

    setearBanners(props.banners);
    setearCategorias(props.categorias);
    setearTiendas(props.tiendas);
    if (props.configNivelSuscribible) {
      setearConfigNivelSuscribible(props.configNivelSuscribible);
    }
    if (props.user) loadUser(props.user);
    await loadMap();
    setearMapsCargado();
    if ($direccionActiva.value) {
      setearZonasDespachoDisponibles();
    }
    if (direccionUser) {
      setearDireccionEncontrada(direccionUser);
    }
    const seleccionoVentana = getCookie("seleccionoVentana", cookies);
    if (!direccionUser && seleccionoVentana != "1" && tipoPedido != "3") {
      await seleccionarPrimeraVentanaDisponible();
    }

    if (
      tipoPedido == "3" &&
      $ventanaActiva.value &&
      $direccionActiva.value &&
      !esDireccionDeTienda($direccionActiva.value)
    ) {
      setearDireccionTiendaParaRetiro();
    }

    deleteCookie("membresia", cookies);

    if (props.membresia && props.configNivelSuscribible) {
      const meses = +props.membresia;
      const membresia = props.configNivelSuscribible.data?.membresias.find(
        (membresia) => membresia.meses === meses,
      );
      if (!membresia) return;
      setearComponenteActivoModalDespachoLogin("PagoMembresia");
      abrirModalDespachoLogin();
      setearMembresiaModalDespacho(membresia);
    }
    const errorRegistro = getCookie("errorRegistro", cookies);
    if (errorRegistro) {
      setearComponenteActivoModalDespachoLogin("RegistroLogin");
      abrirModalDespachoLogin();
    }
    if (props.configTerminosCondiciones) {
      setearConfigTerminosCondiciones(
        props.configTerminosCondiciones.data ?? "",
      );
    }
    if (!getCookie("carritoVigente", cookies) && props.user) {
      getCarritoSegundoPlano();
    }
    if (props.codigoReferido && !props.user) {
      const response = await getReferidor(props.codigoReferido);
      if (response.referidor) {
        const referidor = {
          codigo_referido: props.codigoReferido,
          nombre: response.referidor,
        };
        setearReferidor(referidor);
        abrirModalDespachoLogin();
      }
    }
    if (props.registroReferido == "true") {
      setearModalReferidos(true);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const cd = urlParams.get("cd");
    if (cd) {
      setearForzarCd(true);
    }

    if (
      props.forzarDespachoFuturo &&
      (!$ventanaActiva.value ||
        new Date($ventanaActiva.value.fecha) < new Date())
    ) {
      seleccionarProximaVentanaFutura();
    }
  });
</script>
